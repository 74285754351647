.login-container {
  width: 100%;
  display: flex;
}
.banner-login-desktop {
  width: 47%;
}
.login-form-desktop {
  width: 53%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-login {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.form-description {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.tb-container {
  margin-top: 82px;
  margin-bottom: 16px;
}
.button-fp {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: var(--color-text-extra-light);
  position: absolute;
  right:2px;
  background-color: white;
  bottom:113px;
}
.password-content-left {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: var(--color-text-light);
  margin-left: 5px;
}
.checkbox-image {
  box-sizing: border-box;

  width: 16px;
  height: 16px;

  background: #ffffff;

  border: 1px solid #667085;
  border-radius: 4px;
}

.checkbox-container {
  display: flex;
  gap: 267px;
  white-space: nowrap;
}

.signup-button {
  background-color: var(--color-white);
  color: var(--color-brand-color);
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}
.footer-l {
  width: 60%;
  margin-top: 60px;
  margin-bottom: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-msg {
  color: red;
}
.password-container,
.email-container {
  margin-bottom: 20px;
}
.checkbox-input {
  display: flex;
}
.login-form-mobile,
.sliderbutton-container-mobile,
.backbutton-mobile,
.banner-login-mobile {
  display: none;
}
.backbutton-desktop {
  margin-left: -715px;
  margin-top: 50px;
}

.login-back-btn{
  display: none;
}

@media screen and (max-width: 480px) {
  .login-form-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .backbutton-mobile {
    /* display: block; */
    /* margin-top: 5px; */
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .banner-login-desktop {
    display: none;
  }
  .login-form-desktop{
    width: 100%;
  }
  .banner-login-mobile {
    display: block;
    width: 100%;
  }
  .sliderbutton {
    width: 40px;
    height: 40px;
  }
  .sliderbutton-container-mobile {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .form-login {
    width: 94%;
  }
  .button-fp-mobile{
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: var(--color-text-extra-light);
    position: absolute;
    right: 2px;
    background-color: white;
    bottom: 124px;
    padding-right: 14px;
  }

  .checkbox-container {
    gap: 110px;
    margin-bottom: 10px;
  }
  .footer-l {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 80%;
    display: flex;
    flex-direction: row;
    white-space: nowrap;
  }
  .login-container {
    flex-direction: column;
  }
  .tb-container {
    margin-bottom: 20px;
  }
  .password-content-right{
    right: 16px;
    bottom: 121px;
  }
  .form-container{
    width:100%;
  }

  .tb-container{
    margin-top: 30px;
  }
  .login-back-btn{
    text-align: left !important; 
    width: 90vw;
    margin-top: 20px;
    display: block;
  }  
}

@media (min-width: 800px) and (max-width: 1200px) {
  .backbutton-desktop {
    margin-left: -271px;
    margin-top: 50px;
  }
}