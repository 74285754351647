.registration-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.banner-r-desktop {
  width: 47%;
}
.registration-form-desktop {
  width: 53%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tb-container-r {
  margin-top: 32px;
  margin-bottom: 16px;
}
.form-registration {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 15px; */
  padding: 46px;
}

.footer-r {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-content {
  font-size: 14px;
  font-weight: 700;
  color: var(--color-text-extra-light);
}
.login-button {
  background-color: var(--color-white);
  color: var(--color-brand-color);
  font-weight: 700;
  font-size: 14px;
}
.terms-policy-container {
  margin-top: 32px;
}
.backbutton-desktop {
  margin-left: -430px;
  margin-top: 50px;
}
.backbutton-mobile,
.button-icon-mobile-container,
.registration-form-mobile,
.banner-r-mobile {
  display: none;
}

.login-back-btn-r{
  display: none;
}

@media screen and (max-width: 480px) {
  .backbutton-mobile {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    margin-left: 10px;
  }
  .button-icon-mobile-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .button-icon-mobile {
    width: 40px;
    height: 40px;
  }
  .registration-container {
    flex-direction: column;
    width: 100%;
  }
  .banner-r-mobile {
    display:block;
    width: 100%;
  }
  .tb-container-r {
    margin-left: 20px;
    margin-top: 20px;
  }
  .registration-form {
    width: 100%;
  }
  /* .backbutton-desktop,
  .registration-form-desktop,.banner-r-desktop {
    display: none;
  } */

  .banner-r-desktop {
    display: none;
  }

  .registration-form-desktop{
    width: 100%;
  }

  .registration-form-mobile {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .form-registration {
    width: 95%;
    /* margin-left: 20px; */
    padding: 0;
  }
  .backbutton-desktop{
    margin-top: 0;
  }
  .login-back-btn-r{
    display: block;
    width: 90vw;
    margin-top: -50px;
  }
  
}
